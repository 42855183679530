module.exports = [{
      plugin: require('../../../../libs/dce-openpages/gatsby/gatsby-plugin-prevent-initial-scroll-behavior/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../libs/dce-openpages/gatsby/gatsby-plugin-chatbot/gatsby-browser.js'),
      options: {"plugins":[],"chatFrameUrl":"https://chat.dnb.no/index.html?noHeader&noWelcome"},
    },{
      plugin: require('../../../../libs/dce-openpages/gatsby/gatsby-plugin-emotion-cache/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../libs/dce-openpages/gatsby/gatsby-plugin-dynatrace/gatsby-browser.js'),
      options: {"plugins":[],"enabled":true,"dynatraceServer":"dyw50317.live.dynatrace.com","applicationId":"APPLICATION-EE3A5918ED0F120F","apiToken":"dt0c01.4IDDLA36FBGWNNRSUARBCBIB.BTRVXCN4HYXJ5CR4LMMLYJR54FPOEOG3J5ODAZDLST2B2AS7RULUVQWCZJNPKRME","mode":1,"disablePaths":["^/preview(?:/|$)"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
