import React from 'react'
import { ChatBot } from '@dnb-web-static-libs/gatsby-plugin-chatbot'
import { m, AnimatePresence, LazyMotion } from 'framer-motion'
import { isHomeTemplate } from 'utils/isHomeTemplate'
import { getLocaleByLanguage } from '@dnb-web-static-libs/intl'
import { defaultLanguage } from 'intl/getLocale'

if (typeof window === 'undefined') {
  // for SSR
  React.useLayoutEffect = React.useEffect
}

// Lazy load framer-motion
// https://www.framer.com/motion/guide-reduce-bundle-size/#lazy-loading
const frameMotionFeatures = () => import('./frameMotionFeatures.js').then((res) => res.default)

const HomePageTransitionWrapper = ({ children, props }) => {
  // we need special transition logic for home pages
  const template = props.data && props.data.aemPage && props.data.aemPage.template
  const isHomePage = template && isHomeTemplate(template)
  if (isHomePage) {
    const locationKey = props.location.key
    return (
      <LazyMotion features={frameMotionFeatures}>
        <AnimatePresence initial={false} mode="popLayout">
          <m.div
            key={locationKey}
            initial={{
              '--home-transition-section-opacity': 0,
              '--home-transition-static-visibility': 'visible'
            }}
            animate={{
              '--home-transition-section-opacity': 1,
              '--home-transition-static-visibility': 'visible'
            }}
            exit={{
              '--home-transition-section-opacity': 0,
              '--home-transition-static-visibility': 'hidden'
            }}
            transition={{ duration: 0.25, ease: 'easeInOut' }}
          >
            {children}
          </m.div>
        </AnimatePresence>
      </LazyMotion>
    )
  }

  return children
}

const isCypressTestEnvironment =
  // Environment varible from Cypress for client side
  process.env.GATSBY_CYPRESS_ENV_TYPE === 'test'

/**
 * @type {import('gatsby').GatsbyBrowser['wrapPageElement']}
 */
export const wrapPageElement = ({ element, props }) => {
  const language = props.pageContext.language
  // We do not want to have ChatBot and HomeTransition for Cypress Test environment
  const content = isCypressTestEnvironment ? (
    element
  ) : (
    <>
      <HomePageTransitionWrapper props={props}>{element}</HomePageTransitionWrapper>
      <ChatBot
        locale={getLocaleByLanguage(language)}
        defaultLocale={getLocaleByLanguage(defaultLanguage)}
      />
    </>
  )
  return content
}
